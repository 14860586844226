<template>
  <div class="contaier">
    <van-sticky>
      <van-nav-bar
          @click-left="onClickLeft"
          style="background-color: #000;color: #fff;border-bottom: 1px solid #e4e7ed;"
      >
        <template #left>
          <div class="bar_left">
            <van-icon name="arrow-left" size="20" color="#fff"/>

          </div>
        </template>
        <template #title>
          <div class="bar_title">
            {{productName}}
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="tick">
      <div class="left">
        <div class="price upspan">{{"3999814.65"}}</div>
        <div class="detail">
          <!--<span class="cny"> ≈ {{tick != undefined ? tick.cny : 0.00}} CNY</span>-->
          <span class="rise downspan" >{{"1.57"}}%</span>
        </div>
      </div>
      <div class="right">
        <div class="b">
          <div class="col">
            <span>{{"40226.6"}}</span>
            <span>{{"38900"}}</span>
            <span>{{"286/q"}}</span>
          </div>
          <div class="col title">
            <span class="t">{{"high"}}</span>
            <span class="t">{{"slow"}}</span>
            <span class="t">24H</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tools">
      <div class="period">
        <!--        <u-tabs :list="list" class="klineId" bg-color="rgb(16, 23, 37)" inactive-color="rgb(88,116,152)" :bold="false"-->
        <!--                :is-scroll="false" :current="current" @change="periodTabChange"></u-tabs>-->
        <van-tabs @change="loadChart()" v-model="active" class="klineId" line-width="20px" background="#000" title-inactive-color="#587498" title-active-color="rgb(41, 121, 255)">
          <van-tab title="1min"/>
          <van-tab title="1h"/>
          <van-tab title="1d"/>
          <van-tab title="1w"/>
          <van-tab title="1m"/>
        </van-tabs>
      </div>
      <div class="flexBox">
        <div class="more" @click="showSet = !showSet"><van-icon name="setting" size="17"/></div>
      </div>
    </div>
    <div class="echars">
      <div id="chars"></div>
    </div>
    <div class="trade">
      <button @click="onTrade('bull')" class="trade-btn upBg"> {{ $t('cur.p1') }}</button>
      <button @click="onTrade('bear')" class="trade-btn downBg">{{ $t('cur.p2') }}</button>
    </div>
    <!--买张、买跌弹出层-->
    <van-popup round style="width:90%;height:auto;" v-model:show="showPopup" :style="{ padding: '10px' }">
      <div style="text-align: center;font-weight:bold;">{{productName}}</div>
      <div style="margin-top: 20px;border-bottom:2px solid #000; "></div>
      <div style="margin-top: 20px;width: 100%;overflow-x: auto;white-space: nowrap;">
        <p style="font-size:13px;text-align: left;font-weight:bold;">Point</p>
        <div @click="choosePoint(index,item.point)" :style="pointActive == index? 'border:1px solid #FCBF1E' : ''" v-for="(item,index) in pointList" :key="index" style="margin-top: 5px;border-radius:6px;width: 22%;height:90px;background-color:#eee;
        display: inline-block;margin-right: 28px;box-sizing: border-box;text-align: center">
          <p style="margin-top:6px;font-size:13px;">Point</p>
          <p style="margin-top:6px;font-weight: bold;font-size: 30px;color:#FCBF1E;">{{item.point}}</p>
          <p style="margin-top:6px;font-size:13px;color:red;">{{item.percentage}}</p>
        </div>
      </div>
      <div style="margin-top: 20px;width: 100%;overflow-x: auto;white-space: nowrap;">
        <p style="font-size:13px;text-align: left;font-weight:bold;">Kwota inwestycji</p>
        <div @click="choosePrice(index,item)" :style="priceActive == index? 'border:1px solid #FCBF1E' : ''" v-for="(item,index) in priceList" :key="index" style="margin-top: 5px;border-radius:6px;width: 18%;height:40px;background-color:#eee;
        display: inline-block;margin-right: 2.5%;box-sizing: border-box;text-align: center;">
          <p style="line-height: 40px;font-weight: bold;font-size: 14px;" :style="priceActive == index? 'color:#FCBF1E' : '#000'">${{item}}</p>
        </div>
        <div style="margin-top: 5px;border-radius:6px;width: 18%;height:40px;
        display: inline-block;box-sizing: border-box;text-align: center;">
          <input class="priceInput" style="line-height: 40px;width: 100%;box-sizing: border-box;border:none;background-color:#eee;
          height:100%;display:inline-block;" placeholder="Inna kwota" type="number" min="0" v-model="price"/>
        </div>
      </div>
      <van-row style="text-align: center;margin-top: 20px;font-weight:bold;">
        <van-col span="6">Nazwa</van-col>
        <van-col span="6">Kierunek</van-col>
        <van-col span="6">Aktualna</van-col>
        <van-col span="6">Kwota</van-col>
      </van-row>
      <van-row style="text-align: center;margin-top: 20px;font-weight:bold;">
        <van-col span="6">{{productName}}</van-col>
        <van-col span="6" :style="text == 'bull' ? 'color:#2FCA67;' : 'color:#CA240E;'">{{text}}</van-col>
        <van-col span="6" :style="text == 'bull' ? 'color:#2FCA67;' : 'color:#CA240E'">2520.49</van-col>
        <van-col span="6" style="color:#FCBF1E;">${{price}}</van-col>
      </van-row>
      <van-button @click="addSilTran" style="margin-top:20px;" color="#FCBF1E" block>PotwierdÃ zamówienie</van-button>
      <p style="margin-top:10px;font-weight: bold;color: #FCBF1E">Przewidywanyzysk:$ {{accountBalance}}</p>
    </van-popup>

  </div>

</template>
<script>
import * as echarts from 'echarts';
import {addSilTransateFlow, updateAccount} from "@/api/silTransateFlow";
import {getUserInfoByName} from "@/api/user";
import {showToast} from "vant";

export default {
  data(){
    return{
      accountBalance: 0,
      point: 0,
      productName: '',
      price: 50,
      text: "bull",
      priceActive: -1,
      pointActive: -1,
      priceList:[50,100,300,500],
      pointList:[
        {point:"30S",percentage:'Zysk20%'},
        {point:"60S",percentage:'Zysk30%'},
        {point:"90S",percentage:'Zysk40%'},
        {point:"120S",percentage:'Zysk45%'},
      ],
      showPopup: false,
      showSet: false,
      defaultPeriod: '5min',
      mainIndex: "0",
      subIndex: "4",
      option:{
        title: {
          text: '',
          left: 0
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          data: ['dateK', 'MA5', 'MA10', 'MA20', 'MA30']
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLine: { onZero: false },
          splitLine: { show: false },
          min: 'dataMin',
          max: 'dataMax'
        },
        yAxis: {
          scale: true,
          splitArea: {
            show: true
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 50,
            end: 100
          },
          {
            show: true,
            type: 'slider',
            top: '90%',
            start: 50,
            end: 100
          }
        ],
        series: [
          {
            name: 'dateK',
            type: 'candlestick',
            data: [],
            itemStyle: {
              color: '#ec0000',
              color0: '#00da3c',
              borderColor: '#8A0000',
              borderColor0: '#008F28'
            },
            markPoint: {
              label: {
                formatter: function (param) {
                  return param != null ? Math.round(param.value) + '' : '';
                }
              },
              data: [
                {
                  name: 'Mark',
                  coord: ['2013/5/31', 2300],
                  value: 2300,
                  itemStyle: {
                    color: 'rgb(41,60,85)'
                  }
                },
                {
                  name: 'highest value',
                  type: 'max',
                  valueDim: 'highest'
                },
                {
                  name: 'lowest value',
                  type: 'min',
                  valueDim: 'lowest'
                },
                {
                  name: 'average value on close',
                  type: 'average',
                  valueDim: 'close'
                }
              ],
              tooltip: {
                formatter: function (param) {
                  return param.name + '<br>' + (param.data.coord || '');
                }
              }
            },
            markLine: {
              symbol: ['none', 'none'],
              data: [
                [
                  {
                    name: 'from lowest to highest',
                    type: 'min',
                    valueDim: 'lowest',
                    symbol: 'circle',
                    symbolSize: 10,
                    label: {
                      show: false
                    },
                    emphasis: {
                      label: {
                        show: false
                      }
                    }
                  },
                  {
                    type: 'max',
                    valueDim: 'highest',
                    symbol: 'circle',
                    symbolSize: 10,
                    label: {
                      show: false
                    },
                    emphasis: {
                      label: {
                        show: false
                      }
                    }
                  }
                ],
                {
                  name: 'min line on close',
                  type: 'min',
                  valueDim: 'close'
                },
                {
                  name: 'max line on close',
                  type: 'max',
                  valueDim: 'close'
                }
              ]
            }
          },
          {
            name: 'MA5',
            type: 'line',
            data: this.calculateMA(5),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA10',
            type: 'line',
            data: this.calculateMA(10),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA20',
            type: 'line',
            data: this.calculateMA(20),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          },
          {
            name: 'MA30',
            type: 'line',
            data: this.calculateMA(30),
            smooth: true,
            lineStyle: {
              opacity: 0.5
            }
          }
        ]
      },
      data0:this.splitData(this.generateRandomData()),
    }
  },
  mounted(){
    this.getAccountBalance();
    // let data = (JSON.parse(localStorage.getItem("userInfo")))
    // getUserInfoByName(data.userName).then(res =>{
    //   if(res.code === 200){
    //     this.accountBalance = res.data.accountBalance;
    //     this.productName = this.$route.query.productName;
    //   }
    // })
    this.loadChart();
  },
  methods: {
    //用户名查询当前用户的余额
    getAccountBalance(){
      let data = (JSON.parse(localStorage.getItem("userInfo")))
      getUserInfoByName(data.userName).then(res =>{
        if(res.code === 200){
          this.accountBalance = res.data.accountBalance;
          this.productName = this.$route.query.productName;
        }
      })
    },
    //添加交易流水
    addSilTran(){
      let data = (JSON.parse(localStorage.getItem("userInfo")));
      getUserInfoByName(data.userName).then(res => {
        if (res.code === 200) {
          let tradeNumber = res.data.tradeAccount == null ? '9VX4jSBw5g' : res.data.tradeAccount;
          let productInfo = this.productName;
          let username = data.userName;
          let point = this.point;
          let entrustedAmount = this.price;
          let status = this.text;
          let direction = this.text;
          if (point == 0) {
            if (point === 0) {
              showToast('point is null');
              return;
            }
            if (!entrustedAmount || entrustedAmount < 0) {
              showToast('entrustedAmount is error');
              return;
            }
            let yue = res.data.accountBalance - entrustedAmount;

            let json = {
              tradeNumber: tradeNumber,
              productInfo: productInfo,
              username: username,
              point: point,
              entrustedAmount: entrustedAmount,
              status: status,
              direction: direction,
              postPurchaseBalance: yue
            }
            if (res.data.accountBalance < entrustedAmount) {
              showToast('Insufficient Balance');
            } else {
              addSilTransateFlow(json).then((res) => {
                if (res.code === 200) {
                  showToast("add success")
                  showToast("buy success")
                  this.showPopup = false;
                  this.accountBalance = res.accountBalance - entrustedAmount
                  let data = {
                    username: username,
                    account: json.postPurchaseBalance
                  }
                  updateAccount(data).then(() => {

                  })
                  updateAccount(data);
                }
              })
            }
          }
        }
      })
      },
        generateRandomNumberWithTwoDecimals(min, max) {
        const range = max - min;
        const randomValue = Math.random() * range + min;
        const fixedValue = randomValue.toFixed(2);
        return fixedValue;
      },
      //随机生成数据
      generateRandomData() {
        let data = [];
        const today = new Date();
        // 初始化一个日期对象用于循环
        let currentDate = new Date(today);
        // 循环遍历前100天
        for (let i = 0; i < 50; i++) {
          let item = [];
          // 格式化日期
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          currentDate.setDate(currentDate.getDate() - 1);
          item.push(formattedDate);
          item.push(this.generateRandomNumberWithTwoDecimals(67, 68));
          item.push(this.generateRandomNumberWithTwoDecimals(66, 69));
          item.push(this.generateRandomNumberWithTwoDecimals(65, 69));
          item.push(this.generateRandomNumberWithTwoDecimals(68, 66));
          data.push(item);
        }
        return data;
      },
      //选择point
      choosePrice(index,item){
        this.priceActive = index;
        this.price = item;
        return index;
      },
      //选择point
      choosePoint(index,point){
        this.point = point;
        this.pointActive = index;
        return index;
      },
      onTrade(type){
        this.showPopup = true;
        this.text = type;
        this.getAccountBalance();
      },
      loadChart(){
        var chartDom = document.getElementById('chars');
        var myChart = echarts.init(chartDom);
        this.data0 = this.splitData(this.generateRandomData());
        this.option.xAxis.data=this.data0.categoryData;
        this.option.series[0].data=this.data0.values;
        myChart.setOption(this.option);
      },
      onClickLeft() {
        this.$router.back();
      },
      ChangeKLinePeriod(period){
        this.defaultPeriod = period;
        this.showSet = false;
      },
      ChangeKLineIndex: function(windowIndex) {
        if (windowIndex > 1) {
          this.subIndex = windowIndex
        } else {
          this.mainIndex = windowIndex
        }
        this.showSet = false;
      },

      splitData(rawData) {
        const categoryData = [];
        const values = [];
        for (var i = 0; i < rawData.length; i++) {
          categoryData.push(rawData[i].splice(0, 1)[0]);
          values.push(rawData[i]);
        }
        return {
          categoryData: categoryData,
          values: values
        };
      },
      calculateMA(dayCount) {
        let data = {
          categoryData: [],
          values: []
        }
        const today = new Date();
        // 初始化一个日期对象用于循环
        let currentDate = new Date(today);
        // 循环遍历前100天
        for (let i = 0; i < 50; i++) {
          // 格式化日期
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          currentDate.setDate(currentDate.getDate() - 1);
          data.categoryData.push(formattedDate);
          let item = [];
          item.push(this.generateRandomNumberWithTwoDecimals(50, 70));
          item.push(this.generateRandomNumberWithTwoDecimals(50, 70));
          item.push(this.generateRandomNumberWithTwoDecimals(50, 70));
          item.push(this.generateRandomNumberWithTwoDecimals(50, 70));
          data.values.push(item);
        }
        let result = [];
        for (let i = 0, len = data.values.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          let sum = 0;
          for (let j = 0; j < dayCount; j++) {
            sum += +data.values[i - j][1];
          }
          result.push(sum / dayCount);
        }
        return result;
      }
    }
  }

</script>
<style scoped lang="css">
.contaier {
  padding-bottom: 70px;
  background-color: #000;
}
.bar_left{
  display: flex;
  align-items: center;
}
.bar_title{
  font-size: 18px;
  color: #fff;
}
.tick {
  display: flex;
  flex-direction: row;
  padding: 15px 15px;
}
.left {
  flex: 1;
}
.price {
  font-size: 25px;
  font-weight: bold;
}

.detail {
  display: block;
  color: rgb(88, 116, 152);
  font-size: 14px;
  padding-top: 5px;
}
.rise {
  margin-left: 0px;
}


.right {
  flex: 1;
}
.b {
  width: 100%;
}
.col {
  float: right;
  display: flex;
  flex-direction: column;
  span-align: right;
}
span {
  display: block;
  height: 20px;
  font-size: 12px;
  color: #ffffff;
}

.title {
  width: 30px;
  span-align: left;
}
.t {
  color: rgb(88, 116, 152);
}
.upspan {
  color: #25af8e;
}
.downspan {
  color: #d24963;
}



.tools {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
.period {
  flex: 1;
}

.flexBox {
  width: 90px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  color: rgb(88, 116, 152);

}

}

.setting {
  position: absolute;
  left: 0;
  top: 37px;
  background-color: rgb(9, 17, 25);
  padding: 10px 10px 30px 10px;
  width: 100%;
  z-index: 9999999;
.row {
.title {
  display: block;
  font-size: 14px;
  padding-bottom: 10px;
  color: #ffffff;
  padding-top: 10px;
}

.items {
  display: flex;
  flex-direction: row;

.item {
  padding: 5px 10px;
  background-color: rgb(17, 30, 50);
  margin-right: 10px;
  color: rgb(88, 116, 152);
  font-size: 12px;
  border: 1px solid transparent;
}

.active {
  border: 1px solid rgb(41, 121, 255);
}
}
}
}

.trade{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000;
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  box-sizing: border-box;
.trade-btn{
  width: 48%;
  height: 40px;
  border: 0px !important;
  font-size: 15px;
  font-weight: normal;
  color: #ffffff;
  border-radius: 5px;
}
}

.upBg{
  background-color: #25af8e;
}
.downBg{
  background-color: #d24963;
}
.echars{
  width: 100%;
  height: 100vh;

}
#chars{
  width: 100vw;
  height: 100%;
}
</style>

